import { html, View, type Html } from 'rune-ts';
import { PostMessageNavigate } from '../../../app/navigate';
import { dataStr } from '../../../util/dataStr';

import style from './ButtonAction.module.scss';
import { typo } from '../../../typography/typo';
import { entries, pipe, map, reduce } from '@fxts/core';

type ButtonActionType = 'white' | 'blue' | 'disabled' | 'line' | 'red';
type ButtonActionSize = 'regular' | 'medium' | 'large';

export interface ButtonActionProps {
  label: string | Html;
  submit?: boolean;
  klass?: string;
  fit?: boolean;

  attr?: Record<string, string>;
  target?: '_blank' | '_self';
  href?: string;
  type?: ButtonActionType;
  /**
   * large : 56 (mobile => 52),
   * medium : 48,
   * regular : 40 (default),
   */
  size?: ButtonActionSize;
  navigate?: PostMessageNavigate & object;
}

export class ButtonAction extends View<ButtonActionProps> {
  readonly typeOptions = {
    white: style.type_white,
    blue: style.type_blue,
    disabled: style.type_disabled,
    line: style.type_line,
    red: style.type_red,
  } as const;

  readonly sizeOptions = {
    regular: `${style.size_regular} ${typo('14_bold')}`,
    medium: `${style.size_medium} ${typo('14_bold')}`,
    large: `${style.size_large} ${typo('16_bold')}`,
  } as const;

  getTypeOption() {
    return (this.data.type && this.typeOptions[this.data.type]) || this.typeOptions.white;
  }

  getSizeOption() {
    return (this.data.size && this.sizeOptions[this.data.size]) || this.sizeOptions.regular;
  }

  setTypeOption(type: ButtonActionType) {
    this.data.type = type;
    this.redraw();
  }

  setSizeOption(size: ButtonActionSize) {
    this.data.size = size;
    this.redraw();
  }

  override template(data: ButtonActionProps) {
    return html`
      <${data.href ? 'a' : 'button'}
        data-fit="${!!data.fit}"
        class="${style.action_button} ${data.klass ?? ''} ${this.getTypeOption()} ${this.getSizeOption()}"
        ${
          data.href
            ? html`href="${data.href}" ${data.target ? html`target="${data.target}"` : ''}
              ${data.navigate ? html`data-post-message="${dataStr(data.navigate)}"` : ''}`
            : html`type="${data.submit ? 'submit' : 'button'}"`
        }
        ${
          data.attr
            ? pipe(
                data.attr,
                entries,
                map(([k, v]) => `${k}=${v}`),
                reduce((a, b) => `${a} ${b}`),
              )
            : ''
        }
        ${this.data.type === 'disabled' ? 'disabled' : ''}
      >
        ${this.data.label}
      </${this.data.href ? 'a' : 'button'}>
    `;
  }
}
