import { type Html, rune, View } from 'rune-ts';
import { Locals } from '../type/locals';

type makeHtml<Data, State> = (args: Data, state: State) => Html;
type makeTemplateArgs<Data, State> = {
  is_mobile: boolean;
  mo: makeHtml<Data, State>;
  pc: makeHtml<Data, State>;
};
export const makeMoPcTemplate =
  <Data, State>({ is_mobile, mo, pc }: makeTemplateArgs<Data, State>) =>
  (data: Data, state: State) => {
    return is_mobile ? mo(data, state) : pc(data, state);
  };

type SharedData = {
  is_mobile?: Locals['is_mobile'];
  webviewapp?: Locals['webviewapp'];
  login_user?: Locals['login_user'];
  plus_crew?: Locals['plus_crew'];
};

export const getSharedData = (view: View): SharedData => {
  return (rune.getSharedData(view) ?? {}) as SharedData;
};

export const makeSharedData = (data: SharedData): SharedData => {
  return {
    is_mobile: data?.is_mobile,
    webviewapp: data?.webviewapp,
    login_user: data?.login_user,
    plus_crew: data?.plus_crew,
  };
};
