import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';

export function pushLoginStack(is_join) {
  MShopUtilF.postMessage({
    navigate: {
      name: 'EmptyScreen',
      params: { uri: `/${T.lang}/login${is_join ? '?is_join=true' : ''}` },
    },
  });
}
