import { makeEmptyScreenNavigate } from '../app/navigate';
import { makePlusUrl } from '../util/url';
import { LocalsStoreMembership } from '../type/locals';
import { entries, filter, map, pipe, toArray } from '@fxts/core';

export const MPS_ADDRESS = '서울 금천구 가산디지털1로 75-24 아이에스비즈타워 10층';
export const MPS_ADDRESS_EN = 'KR 08589 10F, 75-24, Gasan digital 1-ro, Geumcheon-gu, Seoul, Korea';
export const MPS_ADDRESS_ENEN = '3-8 Apple Vly, Irvine CA 92602';
export const MP_ADDRESS_JP = '〒08589 10F, 75-24, Gasan digital 1-ro, Geumcheon-gu, Seoul, Korea';
export const MP_MAIL_ORDER_SALES = '2021-서울금천-1710';

export const TERMS = {
  service: 'service',
  privacy: 'privacy',
  studio_service: 'studio_service',
  studio_privacy: 'studio_privacy',
};

export const MPS_ADDRESS_LANG = {
  kr: MPS_ADDRESS,
  en: MPS_ADDRESS_EN,
  jp: MP_ADDRESS_JP,
};

export const MPS_CUSTOMER_CENTER_NUMBER = {
  kr: '1566-5496',
  en: '1566-5496',
  jp: '+82) 050 5840 0207',
};

export type GNB_MENU = {
  url: string;
  name: string;
  key: string;
  post_message?: object;
};

export type gnb_menu_names =
  | 'creators'
  | 'best'
  | 'limited'
  | 'all_products'
  | 'creator_all_products'
  | 'contents'
  | 'review';

export function getGnbMenu(): Record<gnb_menu_names, GNB_MENU>;
export function getGnbMenu(gnb_menu_name: gnb_menu_names): GNB_MENU;
export function getGnbMenu(gnb_menu_name?: gnb_menu_names) {
  const menus = {
    creators: {
      url: '/@/creators',
      name: ET('mps2::search::creator'),
      key: 'creators',
    },
    best: {
      url: `/@/products?type=best`,
      name: ET('mps2::product::best'),
      key: 'best',
    },
    limited: {
      url: `/@/products?type=limited`,
      name: ET('mps2::product::limited'),
      key: 'limited',
    },
    all_products: {
      url: '/@/products',
      name: ET('mps2::product::all_products'),
      key: 'all_products',
    },
    creator_all_products: {
      url: '/products',
      name: ET('mps2::product::all_products'),
      key: 'creator_all_products',
    },
    contents: {
      url: '/@/todays',
      name: ET('콘텐츠'),
      key: 'contents',
    },
    review: {
      url: '/@/communities',
      name: ET('리뷰'),
      key: 'review',
    },
  };

  return gnb_menu_name ? menus[gnb_menu_name] : menus;
}

export const CREATOR_GNB_MENU_MAP = (
  { domain_name, crew_domain_name }: { domain_name: string; crew_domain_name?: string } = { domain_name: '' },
): Record<'all_products' | 'community' | 'review', GNB_MENU> => ({
  all_products: {
    url: `/${domain_name}/products`,
    name: ET('mps2::product::all_products'),
    key: 'all_products',
    post_message: makeEmptyScreenNavigate(
      crew_domain_name
        ? makePlusUrl(crew_domain_name, `/${domain_name}/products`)
        : `/${domain_name}/products`,
    ),
  },
  community: {
    url: `/${domain_name}/community`,
    name: ET('mps2::cheerup::title1'),
    key: 'community',
    post_message: makeEmptyScreenNavigate(
      crew_domain_name
        ? makePlusUrl(crew_domain_name, `/${domain_name}/community`)
        : `/${domain_name}/community`,
    ),
  },
  review: {
    url: `/${domain_name}/review`,
    name: ET('mps2::review::title'),
    key: 'review',
    post_message: makeEmptyScreenNavigate(
      crew_domain_name
        ? makePlusUrl(crew_domain_name, `/${domain_name}/community`)
        : `/${domain_name}/review`,
    ),
  },
});

export const CREATOR_ADDITIONAL_GNB_MENU_MAP = (
  { domain_name, crew_domain_name }: { domain_name: string; crew_domain_name?: string } = { domain_name: '' },
): Record<'membership' | 'commission' | 'support', GNB_MENU> => ({
  membership: {
    url: `/${domain_name}/membership/main`,
    name: ET('mps2::membership::main::title'),
    key: 'membership',
    post_message: makeEmptyScreenNavigate(
      crew_domain_name
        ? makePlusUrl(crew_domain_name, `/${domain_name}/membership/main`)
        : `/${domain_name}/membership/main`,
    ),
  },
  commission: {
    url: `/${domain_name}/membership/commission`,
    name: ET('mps2::membership::commission::title'),
    key: 'commission',
    post_message: makeEmptyScreenNavigate(
      crew_domain_name
        ? makePlusUrl(crew_domain_name, `/${domain_name}/membership/commission`)
        : `/${domain_name}/membership/commission`,
    ),
  },
  support: {
    url: `/${domain_name}/membership/support`,
    name: ET('mps2::membership::support::title'),
    key: 'support',
    post_message: makeEmptyScreenNavigate(
      crew_domain_name
        ? makePlusUrl(crew_domain_name, `/${domain_name}/membership/support`)
        : `/${domain_name}/membership/support`,
    ),
  },
});

export const create_membership_gnb_groups = (
  creator_additional_gnb_menu_list: ReturnType<typeof CREATOR_ADDITIONAL_GNB_MENU_MAP>,
  locals_store_membership?: LocalsStoreMembership,
) => {
  const membership_exist_gnb_map = {
    membership_plan_existed: 'membership',
    membership_commission_existed: 'commission',
    membership_support_existed: 'support',
  } as const;

  return pipe(
    membership_exist_gnb_map,
    entries,
    filter(([key]) => locals_store_membership?.[key]),
    map(([, value]) => creator_additional_gnb_menu_list[value]),
    toArray,
  );
};

export const GNB_MENU_GROUPS = {
  mps: (product_menus: GNB_MENU[]) => [getGnbMenu('all_products'), ...product_menus],
  plus: (product_menus: GNB_MENU[]) => [
    getGnbMenu('creators'),
    ...product_menus,
    getGnbMenu('contents'),
    getGnbMenu('review'),
  ],
  plus_creator_shop: (product_menus: GNB_MENU[]) => [
    // GNB_MENU_MAP.all_products,
    ...product_menus,
    // GNB_MENU_MAP.contents,
    getGnbMenu('review'),
  ],
  creator_shop: (
    product_menus: GNB_MENU[],
    domain_name: string,
    crew_domain_name?: string,
    no_products?: boolean,
    locals_store_membership?: LocalsStoreMembership,
  ) => {
    const menu_map = CREATOR_GNB_MENU_MAP({ domain_name, crew_domain_name });
    const additional_menu_map = CREATOR_ADDITIONAL_GNB_MENU_MAP({ domain_name, crew_domain_name });

    return [
      ...(no_products ? [] : [menu_map.all_products]),
      ...(!locals_store_membership?.membership_existed
        ? []
        : [...create_membership_gnb_groups(additional_menu_map, locals_store_membership)]),
      ...product_menus,
      menu_map.community,
      menu_map.review,
    ];
  },
};

export const MONEY_SIGN = {
  kr: '원',
  en: '$',
  jp: '¥',
};

export const LANGUAGES = ['kr', 'en', 'jp'];

export const INTL = { kr: '', en: '', jp: '' } as const;
export const INTL_NAME = { kr: '국문', en: '영문', jp: '일문' } as const;

export const EXCLUDE_MAKER_MODULE = 'exclude_maker_module';
