import { html } from 'rune-ts';

export const preventEscape = (val: string) => html.preventEscape(val);

/**
 * @description
 * Rune의 _escape 함수의 개선 버전
 * 기존 Rune의 _escape 함수는 '$' 와 '\\' 고려가 안되어있어 룬 컴포넌트가 깨짐
 */

const escapeMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '`': '&#x60;',
  $: '&#36;',
  '\\': '&#92;',
};

function createEscaper(map: Record<string, string>) {
  function escaper(match: string) {
    return map[match];
  }
  const source = Object.keys(map)
    .map((char) => `\\${char}`)
    .join('|');
  const testRegexp = RegExp(source);
  const replaceRegexp = RegExp(source, 'g');

  return function (string: string) {
    string = string === null ? '' : '' + string;
    return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
  };
}

export const safeEscape = createEscaper(escapeMap);
